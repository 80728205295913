.About {
    height: 100vh;
    min-height: 1080px;
    position: relative;

    color: white;
}

.AboutSpeechBubble {
    background-image: url("./SpeechBubble.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;

    height: 1080px;
    width: 40vw;

    position: absolute;
    left: 1%;
    bottom: -100px;
}

.AboutText {
    height: 65%;
    width: 77%;
    margin: 30% 14% 5% 6%;

    text-align: left;
    color: black;
    font-family: MyMonaco;
    overflow: hidden;
}

.SalutationText{
    margin-bottom: 30px;
}

.ResearchInterestsText{
    font-size: 18px;
    font-weight: bolder;
}

.NavPanel {
    height: 60%;
    margin-top: 10%;

    font-family: MyMonaco;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.SignpostStem {
    background-image: url("./NavPanel/SignContainer.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 1;
    height: 66%;
    min-height: 1010px;
    width: 27%;
    position: absolute;
    right: 12%;
    bottom: -22%;

}

.SignpostShadow {
    background-image: url("./NavPanel/SignContainerShadow.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    mix-blend-mode: overlay;
    z-index: 0;
    height: 66%;
    min-height: 1010px;
    width: 27%;
    position: absolute;
    right: 12%;
    bottom: -22%;
}

.NavButton {
    background-image: url("./NavPanel/Sign.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 9%;
    min-height: 70px;
    width: 90%;

    align-self: center;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 24px;
}

.NavButton:link {
    color: white;
    text-decoration: none;
}

.NavButton:visited {
    color: white;
    text-decoration: none;
}

.NavButton:hover {
    background-image: url("./NavPanel/SignHL.png");
    cursor: pointer;
}

/* Tooltip container */
.AboutToolTip {
    position: relative;
}

/* Tooltip text */
.AboutToolTipText {
    visibility: hidden;
    width: 120px;
    top: 15%;
    right: 105%;
    background-color: rgba(0, 0, 0, 0.75);
    color: rgba(255, 255, 255, 1);
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    font-size: 15px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.AboutToolTip:hover .AboutToolTipText {
    visibility: visible;
}