.ContactMeMainContainer {
    height: 100vh;
    min-height: 1080px;

    position: relative;

    color: white;
}

.PortalContainer {
    height: 430px;
    width: 300px;

    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.Portal {
    background-image: url("./Portal.gif");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 1;
    width: 300px;
    height: 430px;
    left: 50%;
    transform: translate(-50%, 0);
    position: absolute;
}

.Portal:hover {
    background-image: url("./PortalHL.gif");
    cursor: pointer;
}

.PortalText {
    background-image: url("./Sign.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 2;
    transform: rotate(-3deg);
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.PortalShadow {
    background-image: url("./PortalShadow.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    mix-blend-mode: overlay;
    z-index: 0;
    width: 300px;
    height: 430px;
    left: 50%;
    transform: translate(-50%, 0);
    position: absolute;
}

.ContactMePanelContainer {
    height: 80%;
    width: 80%;
    z-index: 5;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.ContactMePanel {
    background-image: url("./ContactMePanel.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;

    height: 100%;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.ContactMePanelCloseButton {
    background-image: url("./ContactMePanelCloseButton.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;

    height: 10%;
    width: 10%;
    position: absolute;
    right: 5%;
    top: 10%;
}

.ContactMePanelCloseButton:hover {
    background-image: url("./ContactMePanelCloseButtonHL.png");
    cursor: pointer;
}

.ContentsContainer {
    width: 70%;
    padding: 3% 3%;
    font-family: MyMonaco;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Box {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.Icon {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    height: 30px;
    width: 30px;
    image-rendering: -moz-crisp-edges;         /* Firefox */
    image-rendering:   -o-crisp-edges;         /* Opera */
    image-rendering: -webkit-optimize-contrast;/* Webkit (non-standard naming) */
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;  /* IE (non-standard property) */
}

.Content {
    font-size: 2vh;
}

.Content:link {
    color: white;
    text-decoration: none;
}

.Content:visited {
    color: white;
    text-decoration: none;
}

.Content:hover {
    color: yellow;
    text-decoration: underline;
}

.CV {
    background-image: url("./DownloadCV.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;

    height: 100%;
    min-height: 50px;
    width: 30%;

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.CV:hover {
    background-image: url("./DownloadCVHL.png");
    cursor: pointer;
}

.CV:link {
    color: white;
    text-decoration: none;
}

.CV:visited {
    color: white;
    text-decoration: none;
}

.CopyrightContainer {
    height: 30px;
    width: 100%;

    right: 0;
    bottom: 0;
    position: absolute;

    font-family: "Times New Roman";
    font-size: 12px;
    color: white;
}

.CopyrightText {
    margin-right: 20px;
    text-align: right;
}