.BookContainer {
    height: 40%;
    width: 40%;
    position: relative;
}

.Book {
    background-image: url("./Book.gif");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 1;
    width: 100%;
    height: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    position: absolute;

    text-align: right;
    font-size: x-large;
    font-weight: bold;
    font-family: MyMonaco;
    color: yellow;
    text-shadow: 0px 1px lightgoldenrodyellow;
}

.BookShadow {
    background-image: url("./BookShadow.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    mix-blend-mode: overlay;
    z-index: 0;
    width: 100%;
    height: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    position: absolute;
}

.PublicationTitle {
    font-size: 16px;
    font-weight: normal;
}

.PublicationHR {
    border: 0;
    border-bottom: 1px dashed lightgoldenrodyellow;
}

.PublicationDate {
    font-size: 10px;
    font-weight: lighter;
    text-align: left;
}

.PublicationPublisher {
    font-size: 10px;
    font-weight: lighter;
    text-align: left;
}

.PublicationCoAuthors {
    font-size: 13px;
    font-weight: lighter;
    text-align: left;
}

.PublicationURLContainer {
    display: flex;
    justify-content: flex-end;
}

.PublicationURL {
    font-size: 14px;
    font-style: oblique;
    font-weight: bold;
    text-align: right;
}

.PublicationURL:link {
    color: white;
    text-decoration: none;
}

.PublicationURL:visited {
    color: white;
    text-decoration: none;
}

.PublicationURL:hover {
    color: yellow;
    text-decoration: underline;
}

.PublicationDescription {
    font-size: 12px;
    text-align: left;
}