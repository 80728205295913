@font-face {
  font-family: MyMonaco;
  src: url("./Monaco.ttf");
}

.App {
  text-align: center;
  height: 900vh;
  min-height: calc(1080px * 9);
  image-rendering: pixelated;
  image-rendering: crisp-edges;

  position: relative;
}

.LastUpdateText {
  font-weight: bold;
  border-bottom: 2px dashed;
}

.ScrollSlowlyText {
  color: yellow;
  font-family: MyMonaco;
  font-size: small;
}
.OptimizedText {
  color: yellow;
  font-family: MyMonaco;
  font-size: smaller;
}

.BG {
  z-index: -50;
  background-image: url("MainBG.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  height: 900vh;
  min-height: calc(1080px * 9);
  width: 100%;

  display: flex;
  flex-direction: column;
}

.BoatContainer {
  height: 100vh;
  min-height: 1080px;
  width: 30vw;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

.Boat {
  height: 30vh;
  min-height: 390px;

  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 5;
}

.CharactersContainer {
  height: 900vh;
  width: 20vh;
  min-height: calc(1080px * 9);
  z-index: 0;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}

.SmallSignpost {
  background-image: url("SignpostSmall.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  background-color: #fafad2;

  z-index: 4;

  position: fixed;
  height: 65px;
  width: 65px;

  right: 25px;
  top: 25px;

  border-radius: 32px;
  border: #fafad2 solid 2px;
}

/* Tooltip text */
.SmallSignpostToolTipText {
  visibility: hidden;
  width: 90px;
  top: 25%;
  right: 105%;
  background-color: rgba(0, 0, 0, 0.75);
  color: rgba(255, 255, 255, 1);
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  font-family: MyMonaco;
  font-size: 12px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

.SmallSignpost:hover {
  cursor: pointer;
  transform: scale(1.5);
  background-color: yellow;
  border: yellow;
}

/* Show the tooltip text when you mouse over the tooltip container */
.SmallSignpost:hover .SmallSignpostToolTipText {
  visibility: visible;
}

.MainPanelScrollLeft {
  height: 100vh;
  min-height: 1080px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  color: white;
}

.MainPanelScrollRight {
  height: 100vh;
  min-height: 1080px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  color: white;
}

.Boat {

}

.CobblestoneRoad {
  max-width: 50px;
  height: 10px;
}

.PaperScrollLeft {
  background-image: url("ScrollLeft.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;

  height: 80vh;
  width: 80vh;

  display: flex;
  justify-content: center;
  align-items: center;
}

.PaperScrollRight {
  background-image: url("ScrollRight.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;

  height: 80vh;
  width: 80vh;

  display: flex;
  justify-content: center;
  align-items: center;
}

.PaperScrollContentLeft {
  height: 85%;
  margin-top: 3%;
  margin-bottom: 12%;
  width: 50%;
  margin-right: 4%;
  margin-left: 7%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.PaperScrollContentRight {
  height: 85%;
  margin-top: 3%;
  margin-bottom: 12%;
  width: 50%;
  margin-right: 7%;
  margin-left: 6%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ActualValues {
  font-family: MyMonaco;
  height: 80%;
  min-width: 80%;
}

.ButtonsPanel {
  width: 100%;
  height: 20%;
  position: relative;
}

.CurrentPageScrollDisplay {
  height: 100%;
  width: 40%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);

  display: flex;
  flex-direction: column;
  justify-content: center;


  font-size: medium;
}

.ButtonNext {
  background-image: url("PaginationNext.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;

  height: 100%;
  width: 20%;

  position: absolute;
  right: 5px;
  bottom: 0px;
}

.ButtonNext:hover {
  background-image: url("PaginationNextHL.png");
  cursor: pointer;
}

.ButtonPrev {
  background-image: url("PaginationPrev.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;

  height: 100%;
  width: 20%;

  position: absolute;
  left: 5px;
  bottom: 0px;
}

.ButtonPrev:hover {
  background-image: url("PaginationPrevHL.png");
  cursor: pointer;
}