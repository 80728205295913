.DragonContainer {
    height: 40%;
    width: 40%;
    position: relative;
}

.Dragon {
    background-image: url("./Dragon.gif");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 1;
    width: 100%;
    height: 100%;
    position: absolute;
}

.DragonShadow {
    background-image: url("./DragonShadow.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    mix-blend-mode: overlay;
    z-index: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}

.DragonStars {
    background-image: url("./DragonStars.gif");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 2;
    width: 100%;
    height: 100%;
    position: absolute;

    text-align: left;
    font-size: x-large;
    font-weight: bold;
    font-family: MyMonaco;
    color: yellow;
    text-shadow: 0px 1px lightgoldenrodyellow;
}

.ProjectTitle {
    font-size: 14px;
    font-weight: normal;
}

.ProjectHR {
    border: 0;
    border-bottom: 1px dashed lightgoldenrodyellow;
}

.ProjectRole {
    font-size: 11px;
    font-weight: lighter;
    text-align: left;
}

.ProjectBottomSideContainer {
    margin-top: 10px;
    width: 100%;
}

.ProjectURL {
    font-size: 12px;
    font-style: oblique;
    font-weight: bold;
    text-align: left;
    float: left;
}

.ProjectURL:link {
    color: white;
    text-decoration: none;
}

.ProjectURL:visited {
    color: white;
    text-decoration: none;
}

.ProjectURL:hover {
    color: yellow;
    text-decoration: underline;
}

.ProjectDate {
    font-size: 10px;
    text-align: right;
    margin-right: 10px;
}

.ProjectThumbnail{
    border-radius: 15px;
    border: 1px solid #ddd;
    max-width: 100%;
    height: auto;
}
.ProjectThumbnail:hover{
    transform: scale(1.5);
}

.ProjectDescriptionList {
    margin-left: 15px;
    padding-left: 0;
    margin-right: 10px;
}

.ProjectDescriptionListItem {
    font-size: 10px;
    text-align: left;
}