.SchoolContainer {
    height: 40%;
    width: 40%;
    position: relative;
}

.School {
    background-image: url("./School.gif");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 1;
    width: 100%;
    height: 100%;
    position: absolute;

    text-align: left;
    font-size: x-large;
    font-weight: bold;
    font-family: MyMonaco;
    color: yellow;
    text-shadow: 0px 1px lightgoldenrodyellow;
}

.SchoolShadow {
    background-image: url("./SchoolShadow.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    mix-blend-mode: overlay;
    z-index: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}

.EducationHeader {
    font-size: large;
}

.EducationHR {
    border: 0;
    border-bottom: 1px dashed lightgoldenrodyellow;
}

.EducationDegree {
    font-size: medium;
}

.EducationYears {
    font-size: small;
}

.EducationDescriptionList {
    margin-left: 15px;
    padding-left: 0;
    margin-right: 10px;
}

.EducationDescriptionListItem {
    font-size: smaller;
    text-align: justify;
}