.MuseumContainer {
    height: 40%;
    width: 40%;
    position: relative;
}

.Museum {
    background-image: url("./Museum.gif");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 1;
    width: 100%;
    height: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    position: absolute;

    text-align: left;
    font-size: x-large;
    font-weight: bold;
    font-family: MyMonaco;
    color: yellow;
    text-shadow: 0px 1px lightgoldenrodyellow;
}

.MuseumShadow {
    background-image: url("./MuseumShadow.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    mix-blend-mode: overlay;
    z-index: 0;
    width: 100%;
    height: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    position: absolute;
}

.CHTitle {
    font-size: 18px;
    font-weight: normal;
}

.CHHR {
    border: 0;
    border-bottom: 1px dashed lightgoldenrodyellow;
}

.CHIssuer {
    font-size: 12px;
    font-weight: lighter;
    text-align: left;
}

.CHBottomSideContainer {
    width: 100%;
}

.CHURL {
    font-size: 14px;
    font-style: oblique;
    font-weight: bold;
    text-align: left;
    float: left;
}

.CHURL:link {
    color: white;
    text-decoration: none;
}

.CHURL:visited {
    color: white;
    text-decoration: none;
}

.CHURL:hover {
    color: yellow;
    text-decoration: underline;
}

.CHDate {
    font-size: 10px;
    text-align: right;
    margin-right: 10px;
}

.CHDescriptionList {
    margin-left: 15px;
    padding-left: 0;
    margin-right: 10px;
}

.CHDescriptionListItem {
    font-size: 12px;
    text-align: justify;
}