.ForgeContainer {
    height: 40%;
    width: 40%;
    position: relative;
}

.Forge {
    background-image: url("./Forge.gif");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 1;
    width: 100%;
    height: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    position: absolute;

    text-align: right;
    font-size: x-large;
    font-weight: bold;
    font-family: MyMonaco;
    color: yellow;
    text-shadow: 0px 1px lightgoldenrodyellow;
}

.ForgeShadow {
    background-image: url("./ForgeShadow.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    mix-blend-mode: overlay;
    z-index: 0;
    width: 100%;
    height: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    position: absolute;
}

.WorkExperienceHeader {
    font-size: large;
}

.WorkExperienceCompany {
    font-size: medium;
}

.WorkExperienceLocation {
    font-size: x-small;
    text-align: right;
}

.WorkExperienceYears {
    font-size: smaller;
    text-align: right;
}

.WorkExperienceHR {
    border: 0;
    border-bottom: 1px dashed lightgoldenrodyellow;
}

.WorkExperienceDescriptionList {
    margin-left: 15px;
    padding-left: 0;
    margin-right: 10px;
}

.WorkExperienceDescriptionListItem {
    font-size: 12px;
    text-align: left;
}