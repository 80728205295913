.KianAvatar {
    height: 15vh;
    min-height: 190px;
    position: absolute;
    left: -3.5vw;
    z-index: 1;
}

.KianAvatar:hover {
    cursor: pointer;
}

.WannaChatText {
    color: white;
    font-family: MyMonaco;
    font-weight: bold;
    font-size: 14px;
}

.VisitorAvatar {
    height: 15vh;
    min-height: 190px;
    position: absolute;
    right: -3.5vw;
    z-index: 1;
}