.TrainingCampContainer {
    height: 40%;
    width: 40%;
    position: relative;
}

.TrainingCamp {
    background-image: url("./TrainingCamp.gif");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 1;
    width: 100%;
    height: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    position: absolute;

    text-align: right;
    font-size: x-large;
    font-weight: bold;
    font-family: MyMonaco;
    color: yellow;
    text-shadow: 0px 1px lightgoldenrodyellow;
}

.TrainingCampShadow {
    background-image: url("./TrainingCampShadow.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    mix-blend-mode: overlay;
    z-index: 0;
    width: 100%;
    height: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    position: absolute;
}

.SkillsTitle {
    font-size: 18px;
    font-weight: normal;
}

.SkillsHR{
    border: 0;
    border-bottom: 1px dashed lightgoldenrodyellow;
}

.SkillGroupsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.SkillContainer {
    display: flex;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 5px;

    text-align: left;
    font-size: 14px;
}

.SkillName {
    flex-grow: 1;
    font-size: 12px;
}

.SkillNameURL:link {
    color: white;
    text-decoration: none;
    font-style: oblique;
}

.SkillNameURL:visited {
    color: white;
    font-style: oblique;
}

.SkillNameURL:hover {
    color: yellow;
}

/* Tooltip container */
.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 2px dotted; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    top: -5px;
    right: 105%;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
}